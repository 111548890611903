import React from "react"
// import Helmet from "react-helmet"
import { Helmet } from "react-helmet"

export default React.memo(({ siteUrl, title }) => {
  const schema = [
    {
      "@context": "http://www.schema.org",
      "@type": "Restaurant",
      name: "Azalea Bar & Kitchen",
      telephone: "828-774-5969",
      url: "https://www.azaleakitchen.com",
      logo:
        "https://azalea-kitchen.s3.amazonaws.com/azalea-logo.jpg",
      image:
        "https://azalea-kitchen.s3.amazonaws.com/azalea-interior-dining-bar-web-3.jpg",
      description:
        "Located within The Residences at Biltmore, all-suite hotel, Azalea Bar & Kitchen offers travelers and locals classic American cuisine using fresh locally-sourced ingredients.",
      address: {
        "@type": "PostalAddress",
        streetAddress: "5 Caledonia Rd.",
        addressLocality: "Asheville",
        addressRegion: "NC",
        postalCode: "28803",
        addressCountry: "United States",
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: "35.5722307",
        longitude: "-82.5446167",
      },
      hasMap: "https://goo.gl/maps/bGRpdHiKW8eReWMx5",
      openingHours:
        "",
      priceRange: "$$",
      paymentAccepted: ["Cash", "Credit Card"],
      servesCuisine: ["Southern", "American", "Burgers", "Tacos"],
      hasMenu: "https://www.azaleakitchen.com/menu/",
      acceptsReservations: "Yes",
      contactPoint: {
        "@type": "ContactPoint",
        telephone: "+1-828-774-5969",
        contactType: "Customer Service",
      },
    },
  ]
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
})
